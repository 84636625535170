import { Chip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import StyledDataGrid from "../common/StyledDataGrid";

const ReportsTable = ({activeTab, tradesData}) => {

    const renderType = ({ row }) => {
        return (
            <Chip variant='outlined' size='small' label={row.strategy_type} />
        )
    }

    const renderOrderType = ({row}) => {
        return (
            <Chip variant='outlined' size='small' color={row.order_type === "B" ? "success" : "error"} label={row.order_type === "B" ? "Buy" : "Sell"} />
        )
    }
    const columns = [
        {
            field: 'instrument',
            headerName: 'Instument Name',
            flex: 1,
            valueGetter: ({value, row}) => {
                if (row.id === 'PROFIT/LOSS') {
                    return row.label;
                }
                return value;
            },
            colSpan: ({value, row}) => {
                if (row.id === 'PROFIT/LOSS') {
                    return 7;
                }
                return 1;
            },
        },
        {
            field: 'strategy_name',
            headerName: 'Strategy Name',
            flex: 1,
        },
        {
            field: 'brokers',
            headerName: 'Broker',
            width: 150,
            valueFormatter: (params) => {
                return Object.keys(params.value).join(', ')
            }
        },
        {
            field: 'created_at',
            headerName: 'Trade Time',
            width: 200,
            valueFormatter: (params) => {
                return moment(params.value).format('YYYY-MM-DD HH:MM:SS')
            }
        },
        {
            field: 'strategy_type',
            headerName: 'Type',
            width: 150,
            renderCell: renderType
        },
        {
            field: 'order_type',
            headerName: 'Order Type',
            width: 150,
            renderCell: renderOrderType
        },
        {
            field: 'lot_size',
            headerName: 'Lots',
            width: 150,
            valueGetter: ({value, row}) => {
                return `${row.lots} (${row.lot_size})`
            }
        },
        {
            field: 'entry_price',
            headerName: 'Price',
            width: 100,
            valueGetter: ({value, row}) => {
                if (row.id === 'PROFIT/LOSS') {
                    return parseFloat(row.profit_loss.toFixed(2));
                }
                return value;
            },
        }
    ]

    const calculateTotalPl = (trades) => {
        let totalProfit = 0;
        trades.forEach(trade => {
            if (trade.order_type === "B") {
                totalProfit -= trade.lots * trade.lot_size * trade.entry_price
            } else if (trade.order_type === "S") {
                totalProfit += trade.lots * trade.lot_size * trade.entry_price;
            }
        })

        return totalProfit
    }

    const [reportsData, setReportsData] = useState([]);

    useEffect(() => {
        if(tradesData && activeTab === 0) {
            if (Array.isArray(tradesData)) {
                setReportsData(prev => {
                    const newData = [...tradesData];
                    newData.push({ id: 'PROFIT/LOSS', label: 'Total Profit/Loss', profit_loss: calculateTotalPl(tradesData) })
                    return newData;
                })
            }
            else {
                setReportsData(tradesData);
                Object.keys(tradesData).map(key => {
                    setReportsData(prev => {
                        const newData = tradesData[key];
                        newData.push({ id: 'PROFIT/LOSS', label: 'Total Profit/Loss', profit_loss: calculateTotalPl(tradesData[key]) })
                        return { ...prev, [key] : newData}
                    })
                })
            }
        } else setReportsData(tradesData)
    }, [tradesData, activeTab])

    const getCellClassName = ({ row, field }) => {
        if (row.id === 'PROFIT/LOSS') {
            if (field === 'instrument') {
                return '!font-semibold';
            }
        }
        return '';
    };

    return (
        Array.isArray(reportsData) ? 
        <StyledDataGrid sx={{ maxHeight: 450, '& .MuiDataGrid-virtualScroller': { overflowX: 'hidden' } }}
            columns={columns} rows={reportsData} 
            disableColumnMenu hideFooter disableRowSelectionOnClick 
        />
        :
        Object.keys(reportsData).map(key =>
        <div key={key} className='flex flex-col gap-4'> 
            <span className='text-lg font-semibold'>{key}</span>
            <StyledDataGrid sx={{ height: 'fit-content', maxHeight: 450, marginBottom: '16px', '& .MuiDataGrid-virtualScroller': { overflowX: 'hidden' } }}
                columns={columns} rows={reportsData[key]}
                disableColumnMenu hideFooter disableRowSelectionOnClick
                getCellClassName={getCellClassName}
            />
        </div>)
    )
}

export default ReportsTable