import { Done } from '@mui/icons-material';
import { Chip } from '@mui/material';
import React, { useState } from 'react';
import AngeloneLogo from "../../assets/angelone_logo.png";
import ShoonyaLogo from "../../assets/shoonya_logo.png";
import ZerodhaLogo from "../../assets/zerodha_logo.png";
import { createAxiosInstance } from '../../Utils/axiosConfig';
import FeedbackSnackbar from '../common/FeedbackSnackbar';
import LoadingBackdrop from "../common/LoadingBackdrop";
import Otp from '../common/Otp';
import OutlinedButton from '../common/OutlinedButton';
import TextInput from '../common/TextInput';

const BrokerAccountLoginCard = ({ brokerAccount, broker }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [finvasiaOtp, setFinvasiaOtp] = useState('');
    const [angelistOtp, setAngelistOtp] = useState(new Array(6).fill(""))
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [displayMsg, setDisplayMsg] = useState({
        type: "success",
        msg: "",
    });
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleFinvasiaOtpChange = (e) => {
        const { value } = e.target;
        if ((parseInt(value) > 0 && parseInt(value) < 999999) || value === "")
            setFinvasiaOtp(value)

        if(value.length === 5 || value.length === 6)
            setConfirmDisabled(false);
        else
            setConfirmDisabled(true);
    }

    const handleBrokerLogin = async () => {
        if ((broker === 'finvasia' && (finvasiaOtp === '')) || (broker === 'angelist' && (angelistOtp.join("") === '' || angelistOtp.join("").length < 6)))
            return;

        setLoading(true);
        try {
            const axiosInstance = createAxiosInstance(true);
            const response = broker === 'finvasia' ?
                await axiosInstance.post(`trades/shoonya/login?totp=${finvasiaOtp}&broker_id=${brokerAccount.id}`) :
                await axiosInstance.post(`trades/angel/login?totp=${angelistOtp.join("")}&broker_id=${brokerAccount.id}`);

            if (response.status === 200) {
                setSnackBarOpen(true);
                setDisplayMsg({ msg: "Broker account login successful", type: "success" });
            }

        } catch (err) {
            setSnackBarOpen(true);
            if (err.response?.data?.detail)
                setDisplayMsg({ msg: err.response.data.detail, type: "error" })
            else
                setDisplayMsg({ msg: err.message, type: "error" });
        } finally {
            setLoading(false);
            setFinvasiaOtp("");
            setAngelistOtp(new Array(6).fill(""));
        }
    }

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex justify-between items-center'>
                <div className="flex gap-3 items-center">
                    <img src={broker === 'finvasia' ? ShoonyaLogo : broker === 'angelist' ? AngeloneLogo : ZerodhaLogo} width='45px' height='45px' />
                    <div className="flex flex-col">
                        <h2 className="text-sm font-medium text-[#293056]">{brokerAccount.nickname}</h2>
                        <p className="text-sm font-normal text-[#3E4784]">Client ID: {brokerAccount.userid}</p>
                    </div>
                </div>

                {brokerAccount.login_token === 'None' ? <OutlinedButton variant='outlined' size='small' onClick={() => broker === 'zerodha' ? window.location.href = 'https://kite.zerodha.com/connect/login?v=3&api_key=' + brokerAccount.api_key : setIsExpanded(prev => !prev)}>{isExpanded ? 'Cancel' : 'Login'}</OutlinedButton>
                    : <Chip color='success' label='Logged in' variant='outlined' icon={<Done fontSize='small' />} />}
            </div>


            {isExpanded && broker === 'finvasia' &&
                <div className='flex justify-between gap-2 items-center'>
                    <TextInput name='finvasiaOtp' value={finvasiaOtp} variant='outlined' size='small' placeholder='Enter your Finvasia OTP' onChange={handleFinvasiaOtpChange} />
                    <OutlinedButton disabled={confirmDisabled} variant='outlined' size='small' onClick={handleBrokerLogin}>Confirm</OutlinedButton>
                </div>
            }

            {isExpanded && broker === 'angelist' &&
                <div className='flex justify-between gap-2 items-center'>
                    <Otp otp={angelistOtp} setOtp={setAngelistOtp} />
                    <OutlinedButton variant='outlined' size='small' onClick={handleBrokerLogin}>Confirm</OutlinedButton>
                </div>   
            }
            
            {snackBarOpen && <FeedbackSnackbar snackBarOpen={snackBarOpen} setSnackBarOpen={setSnackBarOpen} displayMsg={displayMsg} /> }

            {loading && <LoadingBackdrop loading={loading} />}
        </div>
    )
}

export default BrokerAccountLoginCard