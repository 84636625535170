import React from "react";
import OiWidget from "./OiWidget";
import ProfitabilityWidget from "./ProfitabilityWidget";

function DashboardMasonry() {
  return (
    <div className="flex flex-col gap-3 pt-8">
        <ProfitabilityWidget />
        <div className="flex flex-auto gap-3">
            <OiWidget />
        </div>
    </div>
  );
}

export default DashboardMasonry;
