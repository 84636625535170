import React, { useEffect, useState } from 'react'
import { createAxiosInstance } from '../../Utils/axiosConfig'
import DashboardWidgetWrapper from './DashboardWidgetWrapper'

const ProfitabilityWidget = () => {

    const [profitabilityData, setProfitabilityData] = useState({});

    useEffect(() => {
        async function getProfitabilityData() {
            const axiosInstance = createAxiosInstance(true);
            const _profitabilityData = await axiosInstance.get('trades/get-profits/');
            setProfitabilityData(_profitabilityData.data)
        }

        getProfitabilityData();
    }, [])

    return (
        <div className='flex gap-3'>
            <DashboardWidgetWrapper>
                <span className='text-base font-medium'>Today's Profit</span>
                <span className='text-3xl font-semibold'>{profitabilityData['today'] ?? 0}</span>
            </DashboardWidgetWrapper>

            <DashboardWidgetWrapper>
                <span className='text-base font-medium'>This Month's Profit</span>
                <span className='text-3xl font-semibold'>{profitabilityData['month'] ?? 0}</span>
            </DashboardWidgetWrapper>

        </div>
    )
}

export default ProfitabilityWidget