import React, { useEffect, useState } from 'react'
import OiChart from './OiChart'
import { createAxiosInstance } from '../../Utils/axiosConfig';
import LoadingBackdrop from '../common/LoadingBackdrop';
import DashboardWidgetWrapper from './DashboardWidgetWrapper';
import { Autocomplete, createFilterOptions, ToggleButtonGroup, ToggleButton } from "@mui/material";
import TextInput from '../common/TextInput';
import ChangeInOiWidget from './ChangeInOiWidget';
import TotalOiWidget from './TotalOiWidget';
import moment from 'moment';

const filterOptions = createFilterOptions({
    matchFrom: "start",
});

const OiWidget = () => {
    const [loading, setLoading] = useState(false);
    const [oiInstruments, setOiInstrument] = useState([]);
    const [oiConfig, setOiConfig] = useState({
        instrument: null,
        strikeLimit: 5,
        expiry: null,
        chartType: "open interest",
        chartStyle: "bar"
    });
    const [oiData, setOiData] = useState([]);

    const handleInstrumentChange = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            expiry: null,
            instrument: value
        }))
    }

    const handleChartTypeChange = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            chartType: value
        }))
    }

    const handleExpiryChange = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            expiry: value
        }))
    }

    const handleStrikeLimitChanage = (event, value) => {
        setOiConfig(prev => ({
            ...prev,
            strikeLimit: value
        }))
    }

    const handleChartStyleChange = (event, value) => {
        setOiData(undefined);
        setOiConfig(prev => ({
            ...prev,
            chartStyle: value
        }))
    }

    useEffect(() => {
        async function getOiInstruments() {
            setLoading(true);
            const axiosInstance = createAxiosInstance(true);
            const response = await axiosInstance.get('trades/oi-instruments/');
            setLoading(false);
            setOiInstrument(response.data);
            setOiConfig(prev => ({
                ...prev,
                instrument: response.data[0],
                expiry: response.data[0]?.expiry.sort((a, b) => new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD'))[0]
            }));
        }

        getOiInstruments()
    }, [])

    useEffect(() => {
        let oiInterval;
        async function getOiData() {
            const axiosInstance = createAxiosInstance(true);
            const oiDataResponse = await axiosInstance.get(`trades/oi-data/?instrument=${oiConfig.instrument.name}&expiry=${oiConfig.expiry}&type=${oiConfig.chartStyle}`);
            if (oiDataResponse.data?.length > 0) {
                oiConfig.chartStyle === 'bar' ? setOiData(oiDataResponse.data) : setOiData(oiDataResponse.data);
            }
        }

        if (oiConfig.instrument !== null && oiConfig.expiry !== null) {
            getOiData();
            oiInterval = setInterval(getOiData, 180000)
        }

        return () => clearInterval(oiInterval);
    }, [oiConfig])
    
  return (
    <>
    <div className='w-3/4'>
        <DashboardWidgetWrapper>
            <span className='text-base font-medium'>Option Chain</span>
            <div className='flex justify-between items-center'>
                <Autocomplete
                    value={oiConfig.instrument}
                    sx={{width: '25%'}}
                    openOnFocus={true}
                    size="small"
                    options={oiInstruments}
                    getOptionLabel={(option) => option.name}
                    clearOnEscape
                    clearOnBlur
                    noOptionsText="No instruments"
                    onChange={handleInstrumentChange}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    filterOptions={filterOptions}
                    autoComplete={true}
                    renderInput={(params) => (
                        <TextInput {...params} placeholder="Select an instrument" />
                    )}
                />
                
                <ToggleButtonGroup exclusive size='small' value={oiConfig.chartType} onChange={handleChartTypeChange}>
                    <ToggleButton sx={{ textTransform: 'none'}} value="open interest">Open Interest</ToggleButton>
                    <ToggleButton value="change in oi" sx={{ textTransform: 'none' }}>Change in Open Interest</ToggleButton>
                    <ToggleButton value="pcr" sx={{ textTransform: 'none' }}>Put Call Ratio</ToggleButton>
                </ToggleButtonGroup>
            </div>

            <div className='flex gap-3 items-center'>
                <span className='text-sm font-medium'>Expiry Date</span>
                <Autocomplete
                    value={oiConfig.expiry}
                    sx={{ width: '25%' }}
                    openOnFocus={true}
                    size="small"
                    options={oiConfig.instrument?.expiry?.sort((a, b) => new moment(a).format('YYYYMMDD') - new moment(b).format('YYYYMMDD')) ?? []}
                    clearOnEscape
                    clearOnBlur
                    noOptionsText="No expiries"
                    onChange={handleExpiryChange}
                    filterOptions={filterOptions}
                    autoComplete={true}
                    renderInput={(params) => (
                        <TextInput {...params} placeholder="Select an expiry" />
                    )}
                />

                <span className='text-sm font-medium'>Strike Limit</span>
                <Autocomplete
                    value={oiConfig.strikeLimit}
                    sx={{ width: '10%' }}
                    openOnFocus={true}
                    size="small"
                    options={[5, 9, 15, 21]}
                    getOptionLabel={option => option.toString()}
                    onChange={handleStrikeLimitChanage}
                    disableClearable
                    clearOnEscape
                    clearOnBlur
                    autoComplete={true}
                    renderInput={(params) => (
                        <TextInput {...params} placeholder="Select strike limit" />
                    )}
                />

                <ToggleButtonGroup exclusive size='small' sx={{marginLeft: 'auto'}} value={oiConfig.chartStyle} onChange={handleChartStyleChange}>
                    <ToggleButton sx={{ textTransform: 'none', "&.Mui-selected": { backgroundColor: '#3E4784', color: '#FFFFFF'} }} value="line">Line Chart</ToggleButton>
                    <ToggleButton sx={{ textTransform: 'none', "&.Mui-selected": { backgroundColor: '#3E4784', color: '#FFFFFF' } }} value="bar">Bar Chart</ToggleButton>
                </ToggleButtonGroup>    
            </div>

            {oiData && <OiChart oiData={oiData} chartStyle={oiConfig.chartStyle} chartType={oiConfig.chartType} /> }
            
        </DashboardWidgetWrapper>
    </div>
    <div className="flex flex-col gap-3 w-1/4">
        { oiData && <ChangeInOiWidget oiData={oiData} chartStyle={oiConfig.chartStyle} /> }
        { oiData && <TotalOiWidget oiData={oiData} chartStyle={oiConfig.chartStyle} /> }
    </div>
    {loading && <LoadingBackdrop loading={loading} />}
    </>
  )
}

export default OiWidget